<template>
  <div class="article-list">
    <li v-for="(item,index) in articleList" :key="index">
      <a :href="item.link">
        <span class="blogpic">
          <a href="/">
            <img :src="item.imageurl" alt=""/>
          </a>
        </span>
        <h3 class="blogtitle">
          <a :href="'/articles/detail?articleid='+item.articleid">{{ item.title }}</a>
        </h3>
        <div class="bloginfo">
          <p>{{ item.description }}</p>
        </div>
        <div class="autor">
          <span class="lm">
            <el-icon><collection-tag/></el-icon><a class="tags">{{ item.tags }}</a>
          </span>
          <span class="dtime">
            <el-icon><timer/></el-icon>
            {{ item.createTime }}
          </span>
          <span class="viewnum">
            <el-icon><search/></el-icon>
            浏览(<a class="tags">{{ item.click }}</a>)
          </span>
          <span class="readmore">
            <a class="tags" :href="'/articles/detail?articleid='+item.articleid">阅读原文</a>
          </span>
        </div>
      </a>
    </li>
  </div>
</template>
<script>

import {CollectionTag, Timer, Search} from '@element-plus/icons-vue'

export default {
  name: 'articles',
  components: {
    CollectionTag,
    Timer,
    Search
  },
  data() {
    return {
      articleList: [
        {
          articleid: 1,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 2,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 3,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 4,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 5,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 6,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 7,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        },
        {
          articleid: 8,
          imageurl: require('@/assets/images/zd01.jpg'),
          title: 'abc',
          description:
              '用最简单的代码，实现瀑布流布局，没有繁琐的css，没有jq，只需要做到以下就可以实现瀑布流的效果。思路很简单，看成是三列布局，分别用三个ul来调用。帝国cms列表模板，...',
          tags: 'Html|Css',
          createTime: '2020-08-08',
          click: 20
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.article-list {
  li {
    margin-bottom: 10px;
    overflow: hidden;
    padding: 20px;
    background: #fff;
  }

  .blogpic {
    overflow: hidden;
    width: 30%;
    display: block;
    float: left;
    margin-right: 20px;
    border-radius: 5px;
  }

  h3.blogtitle {
    line-height: 30px;
    height: 30px;
    margin: 10px 0;
  }

  .bloginfo p {
    color: #888;
    line-height: 24px;
  }

  .autor {
    width: 100%;
    overflow: hidden;
    clear: both;
    margin: 20px 0 0 0;
    display: inline-block;
    color: #838383;
    font-size: 12px;

    span {
      margin-right: 10px;
      padding-left: 10px;

      .tags {
        color: #096;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .readmore {
      float: right;
    }
  }
}
</style>