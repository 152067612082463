<template>
  <div class="sidebar">
    <div class="about">
      <div class="avatar">
        <img src="@/assets/images/avatar.jpg" alt/>
      </div>
      <p class="abname">小白蟹 | Baikit</p>
      <p class="abposition">博主很懒哦，嗷~</p>
      <div class="abtext">啦啦啦</div>
    </div>
    <div class="search">
      <el-input placeholder="请输入内容" v-model="search" class="input-with-select">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <div class="cloud">
      <h2 class="hometitle">懂得不少呢，嗷~</h2>
      <!-- <el-tag v-for="(item,index)  in tags" :key="index" :color="color()">{{item.title}}</el-tag> -->
      <ul>
        <a
          v-for="(item,index)  in tags"
          :key="index"
          :style="{background:color()}"
        >{{item}}</a>
      </ul>
    </div>
    <div class="paihang">
      <h2 class="hometitle">这是推荐呀，嗷~</h2>
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <a :href="'/articles/detail?articleid='+item.link">
            <b>{{ item.title }}</b>
            <p>
              <img :src="item.pic"/>
              <span>{{item.content}}</span>
            </p>
          </a>
        </li>
      </ul>
    </div>
    <div class="links">
      <h2 class="hometitle">友情链接</h2>
      <ul>
        <li v-for="(item,index) in links" :key="index">
          <a :href="item.link" :title="item.title">{{item.title}}</a>
        </li>
      </ul>
    </div>
    <div class="weixin">
      <h2 class="hometitle">一起快乐摸鱼呀~，嗷~</h2>
      <ul>
        <!-- <img src="~@/assets/images/pay.jpg" /> -->
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tags: [
          'HTML',
          'JS',
          'CSS',
          'PHP',
          'Yaf',
          'Laravel',
          'Swool',
          'Mysql',
          'Mongodb',
          'Docker',
          'Go',
          'Node'
        ],
        list: [
          {
            link: '1',
            title: '晨曦',
            pic: require('@/assets/images/morning.jpeg'),
            content: '悲晨曦之易夕,感人生之长勤'
          },
          {
            link: '2',
            title: '黄昏',
            pic: require('@/assets/images/huanghun.jpeg'),
            content: '月落柳梢头,人约黄昏后'
          },
          {
            link: '3',
            title: '静夜',
            pic: require('@/assets/images/night.jpeg'),
            content: '烟笼寒水月笼沙，夜泊秦淮近酒家'
          }
        ],
        links: [
          {link: '', title: '哇1'},
          {link: '', title: '哇2'},
          {link: '', title: '哇3'},
          {link: '', title: '哇4'}
        ],
        search: ''
      }
    },
    methods: {
      color() {
        return (
          'hsl(' +
          Math.round(Math.random() * 360) +
          ',' +
          Math.round(Math.random() * 100) +
          '%,' +
          Math.round(Math.random() * 80) +
          '%)'
        )
      }
    }
  }
</script>
<style lang="less" scoped>
  .sidebar {
    width: 28%;
    float: right;
    overflow: hidden;
    position: relative;
    .about {
      background: #fff url(~@/assets/images/banner.png) no-repeat top center;
      overflow: hidden;
      .avatar {
        margin: 80px auto 20px;
        width: 100px;
        img {
          width: 100px;
          border-radius: 50%;
        }
      }
      .abname {
        color: #3f3f3f;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
        text-align: center;
      }
      .abposition {
        text-align: center;
      }
      .abtext {
        padding: 20px 40px;
        color: #9a9a9a;
        line-height: 26px;
      }
    }
    .search {
      background: #fff;
      overflow: hidden;
      padding: 20px;
      margin: 20px 0;
    }
    .cloud {
      width: 100%;
      clear: both;
      overflow: hidden;
      background: #fff;
      margin-bottom: 20px;
      .hometitle {
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        font-size: 18px;
        border-bottom: 1px solid #e5e5e5;
        color: #333;
        position: relative;
      }
      ul {
        padding: 20px;
        overflow: hidden;
        a {
          line-height: 24px;
          height: 24px;
          display: block;
          background: #999;
          float: left;
          padding: 3px 11px;
          margin: 10px 10px 0 0;
          border-radius: 8px;
          -moz-transition: all 0.5s;
          -webkit-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          color: #fff;
        }
      }
    }
    .paihang {
      background: #fff;
      overflow: hidden;
      margin-bottom: 20px;
      .hometitle {
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        font-size: 18px;
        border-bottom: 1px solid #e5e5e5;
        color: #333;
        position: relative;
      }
      ul {
        padding: 10px;
        li {
          border-bottom: solid 1px #eaeaea;
          font-size: 14px;
          margin: 0 0 10px 0;
          padding: 0 0 10px 0;
          overflow: hidden;
          b {
            height: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
          }
          p {
            line-height: 24px;
            color: #888;
          }
        }
      }
    }
    .links {
      width: 100%;
      background: #fff;
      overflow: hidden;
      .hometitle {
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        font-size: 18px;
        border-bottom: 1px solid #e5e5e5;
        color: #333;
        position: relative;
      }
      ul {
        padding: 20px;
        overflow: hidden;
        a {
          line-height: 24px;
          margin: 0 10px 0 0;
          display: block;
          float: left;
        }
      }
    }
    .weixin {
      background: #fff;
      overflow: hidden;
      margin: 20px 0;
      .hometitle {
        padding: 0 10px;
        line-height: 50px;
        height: 50px;
        font-size: 18px;
        border-bottom: 1px solid #e5e5e5;
        color: #333;
        position: relative;
      }
    }
  }
</style>