<template>
    <div class="home">
        <el-image class="pic" :src="images"/>
        <section class="section">
            <div class="articles">
                <article-tab></article-tab>
            </div>
            <Sidebar></Sidebar>
        </section>
    </div>
</template>

<script>

import ArticleTab from '@/components/home/article'
import Sidebar from '@/components/sidebar'

export default {
  name: 'home',
  components: {
    ArticleTab,
    Sidebar
  },
  data() {
    return {
      images: require('@/assets/images/header.webp')
    }
  },
  methods: {
    getIndexContent() {
    }
  },
  created() {
    this.getIndexContent()
  }
}
</script>
<style lang="less" scoped>
.home {
  min-height: 100%;
  //   position: relative;
  box-sizing: border-box;

  .pic {
    width: 100%;
    overflow: hidden;
    margin: 20px auto;
  }

  .articles {
    width: 70%;
    float: left;
    overflow: hidden;
  }
}
</style>
