const about = [
    {
        path: '/articles',
        name: 'articles',
        meta: {
            active: '/articles'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/article/index.vue'),
        children: [
            {
                path: '',
                name: 'articlesList',
                meta: {
                    active: '/articles'
                },
                // parent:'/articles',
                component: () => import('../views/article/articles.vue')
            }
            , {
                path: 'detail',
                name: 'articlesDetail',
                meta: {
                    active: '/articles'
                },
                // parent:'/articles',
                component: () => import('../views/article/detail.vue')
            }
        ]
    }
]

export default about