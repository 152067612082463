<template>
  <el-row class="headers">
    <el-col :span="10" class="logo">
      <div class="text">
        <span>小白蟹</span>
      </div>
    </el-col>
    <el-col :span="14">
      <el-menu
          :default-active="$route.meta.active"
          class="el-menu-demo nav"
          mode="horizontal"
          text-color="#909399"
          :router="true"
      >
        <el-menu-item index="/">网站首页</el-menu-item>
        <el-menu-item index="/articles">随笔动弹</el-menu-item>
        <el-menu-item index="/photos">随行摄影</el-menu-item>
<!--        <el-menu-item index="/words">随心絮语</el-menu-item>-->
        <el-menu-item index="/about">关于我</el-menu-item>
        <el-menu-item index="/message">留言</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'headers',
  data() {
    return {
      activeIndex: ''
    }
  }
}
</script>

<style lang="less" scoped>
.headers {
  height: 60px;
  background-color: #ffffff;

  .logo {
    height: 100%;
    text-align: center;
    font-size: 20px;
    display: inline-grid;

    .text {
      align-self: center;
    }
  }

  .nav {
    padding-left: 200px;
  }
}
</style>
