const about = [{

    path: '/message',
    name: 'message',
    meta: {
        active: '/message'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/message/message.vue')

}];

export default about