const about = [{

    path: '/words',
    name: 'words',
    meta: {
        active: '/words'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/words/words.vue')

}]

export default about