<template>
  <footer class="footer">
    <p>
      Design by
      <a href="/" class="link-color">baikit&nbsp;</a>
      <a href="https://beian.miit.gov.cn" target="_blank" class="link-color">粤ICP备16031288号-1</a>
    </p>
  </footer>
</template>
<script>
export default {}
</script>

<style lang="less">
.footer {
  width: 100%;
  background: #333;
  color: #a5a4a4;
  text-align: center;
  padding: 20px 0;
  .link-color {
    color: #a5a4a4
  }
}
</style>