import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/homepage/homepage.vue'

import articles from './articles';
import photos from './photos';
import words from './words';
import about from './about';
import message from './message';
import {ElMessage} from 'element-plus';

const home = [
  {
    path: '/',
    name: 'home',
    meta: {
      active: '/'
    },
    component: Home,
  }
  , {
    path: '/search',
    name: 'search',
    meta: {
      active: '/'
    },
    component: () => import('../views/search/search')
  }
]

const routes = home.concat(articles, photos, words, about, message)
/**
 * key 必须为routes 赋值可以为 routes:routes es 6 语法 同名缩写
 */
const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.length === 0) {
    ElMessage({
      message: '页面无法访问，正在跳转首页...',
      center: true,
      type: "error"
    })
    setTimeout(function () {
      from.path ? next(from.path) : next('/');
    }, 400)

  } else {
    next();
  }

});

export default router
