import {createApp} from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

//加载css
import 'element-plus/dist/index.css'
import './assets/css/index.less';
import './assets/css/style.less';

//加载插件
import axiosPlugin from "@/plugins/axios";
import elementplus from 'element-plus'

const app = createApp(App)

app.use(elementplus)
app.use(axiosPlugin)
app.use(store)
app.use(router)
app.mount('#app')
