<template>
  <div id="app">
    <HeaderTab></HeaderTab>
    <router-view></router-view>
    <footerTab></footerTab>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import HeaderTab from '@/components/header/index'
import FooterTab from '@/components/footer/index'
export default {
  name: 'app',
  components: {
    HeaderTab,
    FooterTab
  },
}
</script>